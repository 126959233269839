import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { setError } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getErrorMesssage } from 'services/axiosHelpers';

const MyAccount = () => {
  const authUser = useSelector(state => state.auth.user);
  const { regions } = useSelector(state => state.appsetting);
  const dispatch = useDispatch();

  const {
    _id: id,
    user_type,
    first_name,
    last_name,
    email,
    regionISbb_q4sg_regionsID
  } = authUser;
  console.log(authUser, 'authUser');
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      first_name,
      last_name,
      email,
      regionISbb_q4sg_regionsID
    }
  });

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const onSubmit = async data => {
    console.log(data, 'dataForm');
    try {
      setLoading(true);
      const ep = endpoint.getEditUserEndpoint(id);
      const res = await axios.patch(ep, { ...data, user_type });
      console.log(res, 'uers update patch');
      if (res?.data?.error) {
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message: res?.data?.error
          })
        );
        return;
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="my-account-form">
      <h3>My Account</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="firstName">
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('First Name')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    name="first_name"
                    {...register('first_name', {
                      required: 'First name is required'
                    })}
                    isInvalid={!!errors.first_name}
                    type="text"
                  />
                </Form.Group>
              </fieldset>
              <span className="field-error">{errors.first_name?.message}</span>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="last_name">
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Last Name')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    placeholder={''}
                    {...register('last_name', {
                      required: 'Last name is required'
                    })}
                    isInvalid={!!errors.last_name}
                    type="text"
                  />
                </Form.Group>
              </fieldset>
              <span className="field-error">{errors.last_name?.message}</span>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="email">
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Email')}
                </legend>
                <Form.Group className="mb-0">
                  <Form.Control
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    isInvalid={!!errors.email}
                    type="email"
                  />
                </Form.Group>
              </fieldset>
              <span className="field-error">{errors.email?.message}</span>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: 'Invalid email address'
                  }
                })}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group> */}
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" controlId="region">
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Region')}
                </legend>
                <Form.Control
                  as="select"
                  {...register('regionISbb_q4sg_regionsID', {
                    required: 'Region is required'
                  })}
                  defaultValue={regionISbb_q4sg_regionsID || ''}
                  isInvalid={!!errors.regionISbb_q4sg_regionsID}
                  className="form-select"
                >
                  <option value="">Select region</option>
                  {regions.map((region, index) => (
                    <option value={region._id} key={index}>
                      {region.name}
                    </option>
                  ))}
                </Form.Control>
              </fieldset>
              <span className="field-error">
                {errors.regionISbb_q4sg_regionsID?.message}
              </span>
            </Form.Group>
          </Col>
        </Row>

        <Row className="d-flex align-items-end">
          <Col md={6}>
            <Form.Group controlId="password">
              <fieldset className={'form-legend-item-wrapper'}>
                <legend className="item-title fs-1 fw-semi-bold">
                  {t('Password')}
                </legend>
                <div className="position-relative">
                  <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 6,
                        message: 'Password must be at least 6 characters long'
                      }
                    })}
                    isInvalid={!!errors.password}
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: 'absolute',
                      right: '10px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer'
                    }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </fieldset>
              <span className="field-error">{errors.password?.message}</span>
            </Form.Group>
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className="align-self-end me-0"
              style={{ height: 'fit-content', maxWidth: '160px' }}
            >
              {loading ? `${t('Saving')}...` : 'Update account'}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MyAccount;
