import { Button, Form, Modal } from 'react-bootstrap';
import FalconCloseButton from 'components/common/FalconCloseButton';

const SupplierContentModal = ({ show, handleClose }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      contentClassName="border-0"
      centered
      size="lg"
    >
      <Modal.Header className="bg-modal-header border-bottom-0">
        <Modal.Title as="h5" className="">
          New content
        </Modal.Title>
        <FalconCloseButton noOutline onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <div className='fw-bold'>Please select below where you would like to add the content:</div>
        <div>
          Insert fixtures ans furniture folder tree for user to work there way
          through to select correct destination folder fixtures and furniture
          accessories, booth, midfloor etc
        </div>
        <div className="d-flex justify-content-center w-100">
          <Button
            variant="primary"
            type="submit"
            style={{ height: 'fit-content', maxWidth: '200px' }}
          >
            Load content form
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default SupplierContentModal;
