import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import PageTitle from 'components/common/PageTitle';
import { Tab, Nav, Dropdown, Form } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import { getIcon } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { setInit, setError, setSettings } from 'redux/appsettingSlice';
import axios from 'axios';
import endpoint from 'services/endpoint';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import Treeview from 'components/common/tree-view/Treeview';
import DeleteUserModal from './user/DeleteModal';
import EditUserModal from './user/EditModal';
import DeleteCategoryModal from './category/category/CategoryDeleteModal';
import EditCategoryModal from './category/category/CategoryEditModal';
import DeleteContentModal from './category/content/ContentDeleteModal';
// import EditContentModal from './category/content/ContentEditModal';
import Loading from 'components/loading';
import { getErrorMesssage } from 'services/axiosHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MyAccount from './MyAccount';
import MyContent from './MyContent';
import SupplierContentModal from './modals/SupplierContentModal';

const Admin = () => {
  const _isMounted = useRef(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appsetting = useSelector(state => state.appsetting);
  const category_tree = appsetting.category_tree;
  // const inline_editing = appsetting.inline_editing;
  const { user_type, user_status } = appsetting.dropdowns;
  const userTypes = [...user_type];
  const regions = appsetting.regions || [];
  //const languages = appsetting.languages || [];
  const statuses = [...user_status];
  const user = useSelector(state => state.auth.user);
  const isAdmin = user && user.user_type && user.user_type === 'Admin';
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [kind, setKind] = useState('account');
  // const [kind, setKind] = useState('categories');
  const [users, setUsers] = useState([]);
  const [openedItems, setOpenedItems] = useState([]);

  // const [contents, setContents] = useState([]);
  // const [reports, setReports] = useState([]);

  const title = isAdmin ? 'System Administration' : 'My account';

  const [selectedUser, setSelectedUser] = useState({});
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleDeleteUserModalClose = () => setShowDeleteUserModal(false);
  const handleEditUserModalClose = () => setShowEditUserModal(false);
  const [showSupplierContentModal, setShowSupplierContentModal] =
    useState(false);
  const closeSupplierModal = () => setShowSupplierContentModal(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const handleDeleteCategoryModalClose = () =>
    setShowDeleteCategoryModal(false);
  const handleEditCategoryModalClose = () => setShowEditCategoryModal(false);

  const [selectedContent, setSelectedContent] = useState({});
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
  // const [showEditContentModal, setShowEditContentModal] = useState(false);
  const handleDeleteContentModalClose = () => setShowDeleteContentModal(false);
  // const handleEditContentModalClose = () => setShowEditContentModal(false);

  // const [parentCategoryId, setParentCategoryId] = useState('');

  const kinds = isAdmin
    ? [
        {
          key: 'account',
          name: t('My account')
        },
        {
          key: 'content',
          name: t('My content')
        },
        // {
        //   key: 'categories',
        //   name: t('Content Management')
        // },
        {
          key: 'users',
          name: t('Users')
        }
      ]
    : [
        {
          key: 'account',
          name: t('My account')
        },
        {
          key: 'content',
          name: t('My content')
        }
      ];

  // const onChageInLineEdit = e => {
  //   const inline_editing = e.target.checked;
  //   dispatch(
  //     setSettings({
  //       inline_editing
  //     })
  //   );
  // };

  const onSelectUser = _id => {
    if (_id === null) {
      setSelectedUser({});
      return;
    }
    const user = users.find(u => u._id === _id);
    if (user) {
      setSelectedUser(user);
    }
  };

  const onEdit = (e, _id) => {
    onSelectUser(_id);
    setShowEditUserModal(true);
  };

  const onAddUser = () => {
    onSelectUser(null);
    setShowEditUserModal(true);
  };

  const onDelete = _id => {
    onSelectUser(_id);
    setShowDeleteUserModal(true);
  };

  // eslint-disable-next-line react/prop-types
  const ActionButtons = ({ data }) => {
    // eslint-disable-next-line react/prop-types
    const { _id } = data;
    return (
      <CardDropdown iconClassName="fs--1">
        <div className="py-2">
          <Dropdown.Item onClick={e => onEdit(e, _id)}>
            <span>
              {getIcon({
                icon_component: 'ri/RiEditBoxFill',
                icon_component_attributes: { fontSize: 16, marginRight: 10 }
              })}
            </span>
            <span>{t('Edit')}</span>
          </Dropdown.Item>
          <Dropdown.Item onClick={() => onDelete(_id)}>
            <span>
              {getIcon({
                icon_component: 'md/MdDelete',
                icon_component_attributes: { fontSize: 16, marginRight: 10 }
              })}
            </span>
            <span>{t('Delete')}</span>
          </Dropdown.Item>
        </div>
      </CardDropdown>
    );
  };

  const userColumns = useMemo(() => {
    let result = [];
    result = [
      {
        accessor: 'name',
        Header: t('Name')
      },
      {
        accessor: 'email',
        Header: t('Email')
      },
      {
        accessor: 'region',
        Header: t('Region')
      },
      {
        accessor: 'status',
        Header: t('Status')
      },
      {
        accessor: 'user_type',
        Header: t('Type')
      },
      {
        accessor: 'action',
        Header: t('Action'),
        Cell: rowData => {
          const data = rowData.row.original;
          return (
            <div className="text-center">
              <ActionButtons data={data} />
            </div>
          );
        }
      }
    ];
    return result;
  }, [users]);

  const modifyCategoryStructure = categories => {
    let result = [];
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        const cateChildren = modifyCategoryStructure(category.sub_branches);
        const fileChildren = category.content_list.map(f => {
          return {
            name: f.name,
            id: f._id,
            icon: 'file'
          };
        });
        const children = [...cateChildren, ...fileChildren];
        const newCate = {
          name: category.name,
          id: category._id,
          children
        };
        result = [...result, newCate];
      });
    }
    return result;
  };

  const modifyCategoryOnlyStructure = categories => {
    let result = [];
    if (categories && categories.length > 0) {
      categories.forEach(category => {
        const children = modifyCategoryOnlyStructure(category.sub_branches);
        const newCate = {
          name: category.name,
          id: category._id,
          children
        };
        result = [...result, newCate];
      });
    }
    return result;
  };

  const categories = useMemo(() => {
    let result = modifyCategoryStructure(category_tree);
    return result;
  }, [category_tree]);

  const categoriesOnly = useMemo(() => {
    let result = modifyCategoryOnlyStructure(category_tree);
    return result;
  }, [category_tree]);

  const userData = useMemo(() => {
    const tempContacts = users;
    let results = [];
    results = tempContacts.map(c => {
      const status = statuses.find(s => s._id == c.on_holdYN)
        ? statuses.find(s => s._id == c.on_holdYN).name
        : '';
      const region = regions.find(r => r._id == c.regionISbb_q4sg_regionsID)
        ? regions.find(s => s._id == c.regionISbb_q4sg_regionsID).name
        : '';
      return {
        _id: c._id,
        name: `${c.first_name} ${c.last_name}`,
        email: `${c.email}`,
        region,
        status,
        user_type: `${c.user_type}`
      };
    });
    return results;
  }, [users]);

  const handlerAppSettingData = async () => {
    _isMounted.current && setLoading(true);
    try {
      const response = await axios.get(endpoint.settings);
      console.log('-----app_settings----at Admin---------', response.data);
      const data = response.data;

      const background_color = data.background_color;
      const category_tree = data.category_tree;
      const default_route = data.default_route;
      const dropdowns = data.dropdowns;
      const env_files = data.env_files;
      const favicon_16ISfile = data.favicon_16ISfile;
      const favicon_32ISfile = data.favicon_32ISfile;
      const favicon_192ISfile = data.favicon_192ISfile;
      const languages = data.languages;
      const logoISfile = data.logoISfile;
      const name = data.name;
      const regions = data.regions;
      const secondary_color = data.secondary_color;
      const splash_logoISfile = data.splash_logoISfile;
      const title = data.title;
      const translations = data.translations;

      dispatch(
        setInit({
          background_color,
          category_tree,
          default_route,
          dropdowns,
          env_files,
          favicon_16ISfile,
          favicon_32ISfile,
          favicon_192ISfile,
          languages,
          logoISfile,
          name,
          regions,
          secondary_color,
          splash_logoISfile,
          title,
          translations
        })
      );
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  // const onAddCategory = () => {
  //   setSelectedCategory({});
  //   console.log('------Add new Category---------');
  //   setShowEditCategoryModal(true);
  // };

  // const onAddContent = (parentId = '') => {
  //   setSelectedContent({});
  //   setParentCategoryId(parentId);
  //   console.log('------Add new Content---------');
  //   setShowEditContentModal(true);
  // };

  // const addNewContentInCategory = item => {
  //   console.log('-------Add new content---', item);
  //   onAddContent(item.id);
  // };

  const updateSuccess = () => {
    setup();
  };

  const updateCategorySuccess = async () => {
    console.log('------updateCategorySuccess---------');
    await handlerAppSettingData();
  };

  // const onEditCategoryContent = item => {
  //   const isCategory = item.children ? true : false;
  //   if (isCategory) {
  //     console.log('----Category--Edit---------', item);
  //     setSelectedCategory(item);
  //     setShowEditCategoryModal(true);
  //   } else {
  //     console.log('----Content--Edit---------', item);
  //     setSelectedContent(item);
  //     setShowEditContentModal(true);
  //   }
  // };

  const onDeleteCategoryContent = item => {
    console.log('-------Delete------', item);
    const isCategory = item.children ? true : false;
    if (isCategory) {
      setSelectedCategory(item);
      setShowDeleteCategoryModal(true);
    } else {
      setSelectedContent(item);
      setShowDeleteContentModal(true);
    }
  };

  const getUsers = async userType => {
    try {
      _isMounted.current && setLoading(true);
      const ep = `${endpoint.getUsersEndpoint(userType)}`;
      const usersRes = await axios.get(ep);
      const usersData = usersRes.data;

      if (usersData.error) {
        const message = getErrorMesssage(usersData.error);
        dispatch(
          setError({
            isShow: true,
            title: 'Error',
            message
          })
        );
        return [];
      } else {
        return usersData;
      }
    } catch (err) {
      const message = getErrorMesssage(err);
      dispatch(
        setError({
          isShow: true,
          title: 'Error',
          message
        })
      );
      return [];
    } finally {
      _isMounted.current && setLoading(false);
    }
  };

  const getAllusers = async () => {
    let allUsers = [];
    for (let index = 0; index < userTypes.length; index++) {
      const user = userTypes[index];
      const tempUsers = await getUsers(user._id);
      allUsers = [...allUsers, ...tempUsers];
    }
    setUsers(allUsers);
  };

  const setup = async () => {
    setUsers([]);
    await getAllusers();
  };

  useEffect(() => {
    if (isAdmin && pathname === '/admin') {
      setup();
    } else if (!isAdmin && pathname === '/account') {
      setup();
    } else {
      navigate('/home');
    }
  }, []);

  const onAddContent = () => {
    setShowSupplierContentModal(true);
  };
  return (
    <>
      <div>
        {/* <div className="text-center fw-semi-bold">Admin</div> */}
        <PageTitle title={title} />
        {loading ? (
          <Card.Body className="">
            <Loading style={{ marginTop: 50 }} msg="Loading Data..." />
          </Card.Body>
        ) : (
          <div className="page-content mb-6">
            {/* <div className="page-content pt-6 mb-6"> */}
            {/* <div className="settings mb-3 px-3">
              <Form.Check
                type="switch"
                id="inline-editing-switch"
                label="In-line editing"
                className="form-label-nogutter fs-1"
                name="inline_editing"
                defaultChecked={inline_editing}
                onChange={e => onChageInLineEdit(e)}
              />
            </div> */}
            <Tab.Container
              activeKey={kind}
              onSelect={k => setKind(k)}
              id="countries-tab"
              transition={false}
            >
              <Flex justifyContent="between" alignItems="center" className="">
                <Nav variant="tabs" className="flex-row mb-3">
                  {kinds.map((c, index) => (
                    <Fragment key={index}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey={c.key}
                          className="fw-semi-bold fs-1"
                        >
                          {t(c.name)}
                        </Nav.Link>
                      </Nav.Item>
                    </Fragment>
                  ))}
                </Nav>
                {/* {kind === 'categories' && (
                  <Flex
                    justifyContent="between"
                    alignItems="center"
                    className=""
                  >
                    <div className="button-wrapper me-3">
                      <Button
                        color="primary"
                        className="w-100"
                        onClick={onAddCategory}
                      >
                        <span>
                          <FontAwesomeIcon icon={'add'} className="me-2" />
                        </span>
                        <span>{t('Add Category')}</span>
                      </Button>
                    </div>
                    <div className="button-wrapper">
                      <Button
                        color="primary"
                        className="w-100"
                        onClick={onAddContent}
                      >
                        <span>
                          <FontAwesomeIcon icon={'add'} className="me-2" />
                        </span>
                        <span>{t('Add Content')}</span>
                      </Button>
                    </div>
                  </Flex>
                )} */}
                {kind === 'content' && (
                  <div className="button-wrapper">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={onAddContent}
                    >
                      <span>
                        <FontAwesomeIcon icon={'add'} className="me-2" />
                      </span>
                      <span>{t('Add Content')}</span>
                    </Button>
                  </div>
                )}
                {kind === 'users' && (
                  <div className="button-wrapper">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={onAddUser}
                    >
                      <span>
                        <FontAwesomeIcon icon={'add'} className="me-2" />
                      </span>
                      <span>{t('Add User')}</span>
                    </Button>
                  </div>
                )}
              </Flex>
              <Tab.Content>
                {kinds.map((c, index) => (
                  <Fragment key={index}>
                    <Tab.Pane eventKey={c.key} title={c.name} className="p-3">
                      {kind === 'account' && <MyAccount />}
                      {kind === 'content' && <MyContent />}
                      {kind === 'users' && (
                        <div className="extended-table">
                          <AdvanceTableWrapper
                            columns={userColumns}
                            data={userData}
                            sortable
                            pagination
                            perPage={10}
                          >
                            <AdvanceTable
                              table
                              headerClassName="bg-200 text-700 text-nowrap align-middle fs-0"
                              rowClassName="align-middle white-space-nowrap fs-0"
                              tableProps={{
                                bordered: true,
                                striped: true,
                                className: 'fs--1 mb-0'
                              }}
                            />
                            <div className="mt-3">
                              <AdvanceTablePagination table />
                            </div>
                          </AdvanceTableWrapper>
                        </div>
                      )}
                      {kind === 'categories' && (
                        <Treeview
                          data={categories}
                          setItem={onEditCategoryContent}
                          openedItems={openedItems}
                          setOpenedItems={setOpenedItems}
                          deleteItem={onDeleteCategoryContent}
                          addNewChildItem={addNewContentInCategory}
                        />
                      )}
                    </Tab.Pane>
                  </Fragment>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        )}
      </div>
      {showDeleteUserModal && (
        <DeleteUserModal
          show={showDeleteUserModal}
          _id={selectedUser._id}
          handleClose={handleDeleteUserModalClose}
          success={updateSuccess}
        />
      )}
      {showEditUserModal && (
        <EditUserModal
          show={showEditUserModal}
          isAdd={selectedUser._id ? false : true}
          user={selectedUser}
          handleClose={handleEditUserModalClose}
          success={updateSuccess}
        />
      )}
      {showDeleteCategoryModal && (
        <DeleteCategoryModal
          show={showDeleteCategoryModal}
          _id={selectedCategory.id}
          handleClose={handleDeleteCategoryModalClose}
          success={updateCategorySuccess}
        />
      )}
      {showEditCategoryModal && (
        <EditCategoryModal
          show={showEditCategoryModal}
          isAdd={selectedCategory.id ? false : true}
          categoryId={selectedCategory.id}
          categories={categoriesOnly}
          handleClose={handleEditCategoryModalClose}
          success={updateCategorySuccess}
        />
      )}
      {showDeleteContentModal && (
        <DeleteContentModal
          show={showDeleteContentModal}
          _id={selectedContent.id}
          handleClose={handleDeleteContentModalClose}
          success={updateCategorySuccess}
        />
      )}
      {showSupplierContentModal && (
        <SupplierContentModal
          show={showSupplierContentModal}
          handleClose={closeSupplierModal}
        />
      )}
      {/* {showEditContentModal && (
        <EditContentModal
          show={showEditContentModal}
          isAdd={selectedContent.id ? false : true}
          contentId={selectedContent.id}
          categories={categoriesOnly}
          parentCategoryId={parentCategoryId}
          handleClose={handleEditContentModalClose}
          success={updateCategorySuccess}
        />
      )} */}
    </>
  );
};

export default Admin;
