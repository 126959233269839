import './styles.css';

const MyContent = () => {
  return (
    <div>
      <div className="d-flex justify-content-center content-border">
        <span className="fs-18">You have no conten submitted - click 'Add Content' to start</span>
      </div>
      <h5>Content Awaiting Approval</h5>
      <div className="d-flex justify-content-center  content-border">
        <span className="fs-18">There is no content waiting to be approved</span>
      </div>
    </div>
  );
};
export default MyContent;
